<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center" v-if="!interview">
      <v-col>
        <div class="mt-5 text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="body font-weight-thin">Chargement...</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center" v-else-if="interview.audioFile === ''">
      <v-col class="fill-height">
      <v-scroll-y-transition mode="out-in">
        <div class="text-center">
          <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
          <p class="display-1 font-weight-thin grey--text text--lighten-1">
            Aucun fichier audio n'a été chargé
          </p>
          <v-btn small class="mt-4" color="teal" outlined :to="url('upload/audio')">
            <v-icon small>mdi-upload</v-icon> Uploader un fichier
          </v-btn>
        </div>
      </v-scroll-y-transition>
    </v-col>
    </v-row>
    <v-row class="justify-center" v-else-if="!transcription && !loading">
      <v-col cols="4" class="fill-height">
      <v-scroll-y-transition mode="out-in">
        <div class="text-center">
          <v-img class="mx-auto mb-5" src="../../../assets/speech-to-text.svg"
           max-width="400"></v-img>
          <p class="display-1 font-weight-thin grey--text text--lighten-1">
            Transcription automatique
          </p>
          <v-select :items="items" v-model="model"
            item-text="name"
            item-value="value"
            label="Modèle de langue"
            single-line
          ></v-select>
          <v-btn class="mt-4" color="teal" outlined @click="stt">
            <v-icon small>mdi-upload</v-icon> Lancer la transcription
          </v-btn>
        </div>
      </v-scroll-y-transition>
    </v-col>
    </v-row>

    <v-row class="justify-center" v-else>
      <v-col cols="3">
        <p class="title">Actions</p>
        <v-btn block class="mt-4 mr-3" color="teal" :disabled="loading" outlined @click="stt">
          <v-icon small>mdi-refresh</v-icon> Relancer la transcription
        </v-btn>
        <v-btn  block class="mt-4" color="red" outlined :disabled="loading"
          @click="deleteMedia(interviewId, patientId, 'text');  transcription=''">
          <v-icon small>mdi-close</v-icon> Supprimer la transcription
        </v-btn>
        <v-btn  block class="mt-4" color="purple" outlined :disabled="loading"
          @click="deleteMedia(interviewId, patientId, 'audio'); transcription=''">
          <v-icon small>mdi-close</v-icon> Supprimer l'audio
        </v-btn>
        <v-btn  block class="mt-4" color="grey" outlined @click="$router.go(-1)">
          <v-icon small>mdi-close</v-icon> Retour
        </v-btn>
    </v-col>
      <v-col v-if="loading" class="mt-5 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <p class="body font-weight-thin">Chargement...</p>
      </v-col>

      <v-col  v-else-if="transcription && !loading" class="mt-5 text-center">
        <v-textarea
          style="font-family: monospace;"
          :label="`Transcrition de ${interview.name}`"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          :value="transcription">
        </v-textarea>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';

export default {
  name: 'InterviewToolTranscription',
  mixins: [MixinPatients, MixinInterviews],
  props: {
    patientId: String,
    interviewId: String,
  },

  async mounted() {
    this.interview = await this.getInterviewInfo(this.patientId, this.interviewId);
  },

  methods: {
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },

    async stt() {
      this.loading = true;
      this.transcription = await this.getInterviewTranscription(
        this.patientId,
        this.interviewId,
        this.model,
      );
      this.loading = false;
    },
  },

  data: () => ({
    loading: false,
    transcription: '',
    interview: {},
    model: null,
    items: [
      { name: 'Modèle francais standard', value: 'fr-base' },
      { name: 'Modèle français avancé', value: 'fr-advanced', disabled: true },
    ],
  }),
};
</script>
